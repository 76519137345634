export const sortedChannels = [
  {
    id: '97668e49-3772-4182-a302-50170a9c27cc',
    localLogo: '/assets/logos/channels/orf1-logo.svg',
  },
  {
    id: '97668e49-38f6-4813-b5e8-f56dc2a6ead2',
    localLogo: '/assets/logos/channels/orf2-logo.svg',
  },
  {
    id: '97668e48-d3e8-4a2f-b301-c0ea6b361d6a',
    localLogo: '/assets/logos/channels/atv-logo.svg',
  },
  {
    id: '97668e49-49d4-45a2-a932-ad67b9aa5e83',
    localLogo: '/assets/logos/channels/puls4-logo.svg',
  },
  {
    id: '97668e49-5f2f-41d6-8cac-0abb54f6d4ba',
    localLogo: '/assets/logos/channels/servustv-logo.svg',
  },
  {
    id: '97668e49-39d1-4686-8ab9-1b3ca25f4a39',
    localLogo: '/assets/logos/channels/orf3-logo.svg',
  },
  {
    id: '97668e49-4875-446f-9b98-c7f5d7cfa898',
    localLogo: '/assets/logos/channels/puls24-logo.svg',
  },
  {
    id: '97668e49-556e-46d2-a62c-4bd9faf542a2',
    localLogo: '/assets/logos/channels/rtl-logo.svg',
  },
  {
    name: 'SAT.1',
    id: '97668e49-5bb2-4197-967c-b0526ff0547c',
    localLogo: '/assets/logos/channels/sat1-logo.svg',
  },
  {
    id: '97668e49-4794-408e-9145-931ee8b6425f',
    localLogo: '/assets/logos/channels/prosieben_logo.svg',
  },
  {
    id: '97668e49-8f6a-467a-870b-880a046b3e88',
    localLogo: '/assets/logos/channels/vox-logo.png',
  },
  {
    id: '97668e49-1300-4e81-8856-d6e7cade8bc5',
    localLogo: '/assets/logos/channels/kabel-eins-logo.svg',
  },
  {
    id: '97668e49-4ebb-48ee-b9a4-ec819ebf435c',
    localLogo: '/assets/logos/channels/rtl-zwei-logo.svg',
  },
  {
    id: '97668e48-d207-4df0-b419-7b64869d15a6',
    localLogo: '/assets/logos/channels/atv2-logo.svg',
  },
  {
    id: '97668e48-cebb-489e-9fc7-6126aacbae88',
    localLogo: '/assets/logos/channels/das-erste-logo.svg',
  },
  {
    id: '97668e49-97d6-40d6-9817-75e2008665cc',
    localLogo: '/assets/logos/channels/zdf-logo.svg',
  },
  {
    id: '97668e48-c9ad-4df0-8bba-107e5f94ac2c',
    localLogo: '/assets/logos/channels/3sat-logo.svg',
  },
  {
    id: '97668e48-cff8-47b1-bcfe-36f786a04b96',
    localLogo: '/assets/logos/channels/arte-logo.svg',
  },
  {
    id: '97668e49-9688-4943-ac85-03d985ffadd0',
    localLogo: '/assets/logos/channels/zdf-neo-logo.svg',
  },
  {
    id: '97668e49-0c73-4054-bb55-e17e54804678',
    localLogo: '/assets/logos/channels/zdf-info-logo.svg',
  },
  {
    id: '97668e48-d85a-4c10-9efe-eec7e98b999e',
    localLogo: '/assets/logos/channels/br-logo.svg',
  },
  {
    id: '97668e49-7a69-4103-877e-e939ceee510d',
    localLogo: '/assets/logos/channels/swr-logo.svg',
  },
  {
    id: '97668e49-9285-4533-80ad-a99f395a8c0c',
    localLogo: '/assets/logos/channels/wdr-logo.svg',
  },
  {
    id: '97668e49-308d-43df-b69b-2bffbbe8cb52',
    localLogo: '/assets/logos/channels/ndr-logo.svg',
  },
  {
    id: '97668e49-19d5-4754-b9f3-b6f8f7808603',
    localLogo: '/assets/logos/channels/mdr-logo.svg',
  },
  {
    id: '97668e49-0aa7-4d8b-8b0d-19b88a02f48f',
    localLogo: '/assets/logos/channels/hr-logo.svg',
  },
  {
    id: '97668e49-4c39-4bba-a460-87529bc913cc',
    localLogo: '/assets/logos/channels/rbb-logo.svg',
  },
  {
    id: '97668e49-61d9-4f8f-96f3-e08fb6803874',
    localLogo: '/assets/logos/channels/sixx-logo.svg',
  },
  {
    id: '97668e49-3505-49e3-a415-8c23cb26d0eb',
    localLogo: '/assets/logos/channels/one-tv-logo.png',
  },
  {
    id: '97668e49-5409-425b-a404-f5242ff858de',
    localLogo: '/assets/logos/channels/nitro-logo.svg',
  },
  {
    id: '97668e49-84f2-48c2-91d7-2587d9dc70a9',
    localLogo: '/assets/logos/channels/tele5-logo.svg',
  },
  {
    id: '97668e49-45a8-498e-a2fb-db3dfd710c4a',
    localLogo: '/assets/logos/channels/prosieben-max-logo.svg',
  },
  {
    id: '97668e48-ffbf-4bf2-a066-74f264739ae3',
    localLogo: '/assets/logos/channels/dmax-logo.svg',
  },
  {
    id: '97668e49-569f-45cf-bf90-e14ca1affbdb',
    localLogo: '/assets/logos/channels/rtl-up-logo.png',
  },
  {
    id: '97668e49-90ac-499f-a29a-4f7eadb73fea',
    localLogo: '/assets/logos/channels/voxup_logo.png',
  },
  {
    name: 'SAT.1 GOLD',
    id: '97668e49-5aea-4e02-8c2a-62cc34c510a8',
    localLogo: '/assets/logos/channels/sat1-gold-logo.svg',
  },
  {
    id: '97668e49-8622-45df-9c93-2d1d69ff447f',
    localLogo: '/assets/logos/channels/tlc-logo.svg',
  },
  {
    name: 'E! ENTERTAINMENT',
    id: '97668e49-0290-416e-a599-965e83cc8bad',
    localLogo: '/assets/logos/channels/E!-logo.svg',
  },
  {
    id: '97668e48-f2f6-4bf3-9fa9-293cca37a734',
    localLogo: '/assets/logos/channels/cbs-logo.svg',
  },
  {
    id: '97668e49-32a2-4b5e-bbb6-4a94b316556a',
    localLogo: '/assets/logos/channels/ntv-logo.svg',
  },
  {
    name: 'OE24.TV',
    id: '97668e49-3324-4af5-9cb7-1b0dddd9b6da',
    localLogo: '/assets/logos/channels/oe24tv-logo.svg',
  },
  {
    id: '97668e49-3d5b-4821-8402-9a70af9a240b',
    localLogo: '/assets/logos/channels/phoenix-logo.svg',
  },
  {
    id: '97668e48-cb2f-4ff8-98b4-96c99c3466dd',
    localLogo: '/assets/logos/channels/ard-alpha-logo.svg',
  },
  {
    id: '97668e49-1e29-4c51-a11d-000da823c0a8',
    localLogo: '/assets/logos/channels/welt-logo.svg',
  },
  {
    id: '97668e49-1d27-4a8e-9ccc-c4739b1799c0',
    localLogo: '/assets/logos/channels/n24-doku-logo.svg',
  },
  {
    id: '97668e49-1185-4781-b04e-8afb76d1a0cd',
    localLogo: '/assets/logos/channels/kabel-eins-doku-logo.svg',
  },
  {
    id: '97668e49-720c-400a-b54e-b8c9bf36bc68',
    localLogo: '/assets/logos/channels/spiegel-geschichte-logo.svg',
  },
  {
    name: 'CRIME + INVESTIGATION',
    id: '97668e48-de16-402f-88c8-3ed6bc0c470b',
    localLogo: '/assets/logos/channels/crime-investigation-logo.svg',
  },
  {
    id: '97668e49-2196-45b2-950c-29ef26a30005',
    localLogo: '/assets/logos/channels/national-geographic-logo.svg',
  },
  {
    id: '97668e49-1fb0-49d9-8e73-8442c7df5afc',
    localLogo: '/assets/logos/channels/national-geographic-wild-logo.svg',
  },
  {
    id: '97668e48-feab-415b-8417-79b9e088e528',
    localLogo: '/assets/logos/channels/discovery-logo.png',
  },
  {
    id: '',
    localLogo: '/assets/logos/channels/bergblick-logo.svg',
  },
  {
    id: '',
    localLogo: '/assets/logos/channels/home-garden-tv-logo.svg',
  },
  {
    id: '97668e48-cca0-4112-ac2e-c607f564ac87',
    localLogo: '/assets/logos/channels/animal-planete-logo.svg',
  },
  {
    id: '97668e49-5202-4321-9b70-d7872911a5ca',
    localLogo: '/assets/logos/channels/rtl-living-logo.svg',
  },
  {
    id: '97668e48-dfaa-4ac4-b6e8-9f09bf61444d',
    localLogo: '/assets/logos/channels/bongusto-logo.png',
  },
  {
    id: '97668e49-1688-4f9b-beb7-13a70aaa9785',
    localLogo: '/assets/logos/channels/kinowelt-tv-logo.svg',
  },
  {
    id: '97668e49-8d6f-4c45-a0de-aca731a45ea0',
    localLogo: '/assets/logos/channels/universal-tv-logo.svg',
  },
  {
    id: '97668e49-874e-46a5-b681-b62e2eb3be0b',
    localLogo: '/assets/logos/channels/warnertv-film-logo.svg',
  },
  {
    id: '97668e49-8964-4895-acb0-bbdd56fda935',
    localLogo: '/assets/logos/channels/warnertv-serie-logo.svg',
  },
  {
    id: '',
    localLogo: '/assets/logos/channels/warner-tv-comedy-logo.svg',
  },
  {
    id: '97668e49-6e4e-451c-91d0-7ad832047599',
    localLogo: '/assets/logos/channels/sky-krimi-logo.svg',
  },
  {
    id: '97668e49-78eb-4c41-abac-0eb146d1879a',
    localLogo: '/assets/logos/channels/13th-street-logo.svg',
  },
  {
    id: '97668e49-50c9-45f1-8f82-144f2e84f5e7',
    localLogo: '/assets/logos/channels/rtl-crime-logo.svg',
  },
  {
    id: '97668e49-3c56-423d-91da-9c4eac2c8030',
    localLogo: '/assets/logos/channels/rtl-passion-logo.svg',
  },
  {
    id: '97668e49-4df5-4291-abca-fd80b65437ff',
    localLogo: '/assets/logos/channels/romance-tv-logo.svg',
  },
  {
    id: '97668e49-0921-47cf-9224-8ebbadbe7f5f',
    localLogo: '/assets/logos/channels/heimatkanal-logo.svg',
  },
  {
    id: '97668e49-1028-42be-927c-6588631a0013',
    localLogo: '/assets/logos/channels/kabel-eins-classics.svg',
  },
  {
    name: 'SAT.1 EMOTIONS',
    id: '97668e49-5986-49be-bb31-54c286f1d982',
    localLogo: '/assets/logos/channels/sat1-emotions-logo.png',
  },
  {
    id: '97668e49-42b0-4376-bcfb-99cf5023e07c',
    localLogo: '/assets/logos/channels/prosieben-fun-logo.svg',
  },
  {
    id: '97668e48-d70b-4b5d-9ef4-e666a237ac36',
    localLogo: '/assets/logos/channels/axn-logo.svg',
  },
  {
    name: 'ANIXE HD SERIE (steht als ANIXE SERIE)',
    id: '97668e48-cd96-4ab0-84a0-8329bc9d379e',
    localLogo: '/assets/logos/channels/anixe-logo.svg',
  },
  {
    id: '97668e49-0127-4e7d-878a-ebaf1bdfa6c0',
    localLogo: '/assets/logos/channels/srf1-logo.svg',
  },
  {
    id: '97668e49-6083-4aae-a058-0dd7016181e6',
    localLogo: '/assets/logos/channels/srf2-logo.svg',
  },
  {
    id: '97668e48-d9c5-4758-afac-ec7d66f05970',
    localLogo: '/assets/logos/channels/bbc-entertainment-logo.svg',
  },
  {
    id: '97668e48-dca6-44a1-81fb-8943aa800780',
    localLogo: '/assets/logos/channels/bbc-world-news-logo.svg',
  },
  {
    id: '97668e49-8ad2-4278-be9a-7c8fc67b96af',
    localLogo: '/assets/logos/channels/tv5-monde-logo.svg',
  },
  {
    id: '97668e49-4afc-4d51-a0c0-0be4f640d2e6',
    localLogo: '/assets/logos/channels/rai1-logo.svg',
  },
  {
    id: '97668e48-f492-43cd-aa94-80046e10bfd0',
    localLogo: '/assets/logos/channels/cnn-logo.svg',
  },
  {
    id: '97668e49-0384-40ea-8428-439c33424994',
    localLogo: '/assets/logos/channels/euronews-logo.svg',
  },
  {
    id: '97668e48-f3f0-43b3-8fe6-de2e017d8f80',
    localLogo: '/assets/logos/channels/cnbc-logo.svg',
  },
  {
    id: '97668e49-4a66-4379-9bca-0afc21ba4c96',
    localLogo: '/assets/logos/channels/qvc-logo.svg',
  },
  {
    id: '97668e49-04ba-4d1b-87d0-dcf6a9fc829b',
    localLogo: '/assets/logos/channels/tagesschau-logo.svg',
  },
  {
    id: '97668e49-0805-4671-a2a5-0059003f7864',
    localLogo: '/assets/logos/channels/hse-trend-logo.svg',
  },
  {
    id: '97668e48-d045-4885-97c0-59c7cc57d21c',
    localLogo: '/assets/logos/channels/astro-tv-logo.svg',
  },
  {
    id: '97668e48-db4c-401e-a8d6-63be617ef40b',
    localLogo: '/assets/logos/channels/bibel-tv-logo.svg',
  },
  {
    name: 'K-TV',
    id: '97668e49-1714-4352-b9fe-1436808f2035',
    localLogo: '/assets/logos/channels/ktv-logo.svg',
  },
  {
    id: '97668e49-0b1d-4c74-98c6-2cd7d4ad64f1',
    localLogo: '/assets/logos/channels/hope-tv-logo.gif',
  },
  {
    id: '97668e49-1c5c-42ff-b088-98b8a26573f9',
    localLogo: '/assets/logos/channels/mtv-logo.svg',
  },
  {
    id: '97668e49-1b93-4f74-8f83-f56f394e1f23',
    localLogo: '/assets/logos/channels/mtv-live-logo.svg',
  },
  {
    id: '97668e49-052a-4490-9aa5-fe72e7853d01',
    localLogo: '/assets/logos/channels/goldstar-logo.svg',
  },
  {
    id: '97668e49-1a36-44e0-b2e2-cc2124d2e87a',
    localLogo: '/assets/logos/channels/melodie-tv-logo.svg',
  },
  {
    id: '97668e49-7679-42cb-a696-7397c6f8ad93',
    localLogo: '/assets/logos/channels/singray-logo.svg',
  },
  {
    id: '97668e49-3351-47f9-8d99-8e411aeb8591',
    localLogo: '/assets/logos/channels/okto-logo.svg',
  },
  {
    id: '97668e49-915a-46d2-9d12-282b89aaf211',
    localLogo: '/assets/logos/channels/W24-logo.png',
  },
  {
    id: '97668e49-5e34-47fa-8ca9-de37b81328f8',
    localLogo: '/assets/logos/channels/schau-tv-logo.png',
  },
  {
    id: '97668e49-574e-44d3-8f60-8e57d5d8b4f5',
    localLogo: '/assets/logos/channels/tirol-tv-logo.png',
  },
  {
    name: 'LÄNDLE TV',
    id: '97668e49-17ac-4404-a70a-9143c21138a5',
    localLogo: '/assets/logos/channels/laendle-tv-logo.png',
  },
  {
    id: '97668e49-185d-485f-95b9-32047614c051',
    localLogo: '/assets/logos/channels/lt1-logo.png',
  },
  {
    id: '97668e49-75b1-4835-9ae0-66ac8de13aef',
    localLogo: '/assets/logos/channels/super-rtl-logo.svg',
  },
  {
    id: '97668e49-1551-4e15-8566-7c4a600b2f98',
    localLogo: '/assets/logos/channels/kika-logo.svg',
  },
  {
    id: '97668e48-fcb0-4cc6-b9e2-72368d019b5c',
    localLogo: '/assets/logos/channels/disney-channel-logo.svg',
  },
  {
    id: '97668e48-f82f-41c9-817b-a122080f706a',
    localLogo: '/assets/logos/channels/comedy-central-logo.svg',
  },
  {
    id: '97668e49-2e56-4373-8298-542d54137127',
    localLogo: '/assets/logos/channels/nick-logo.svg',
  },
  {
    id: '97668e49-2a84-45b1-ac53-c7e6a435a447',
    localLogo: '/assets/logos/channels/nicktoons-logo.svg',
  },
  {
    id: '97668e48-f0a0-4ccd-b826-f9ceed4105bc',
    localLogo: '/assets/logos/channels/cartoon-network-logo.svg',
  },
  {
    id: '97668e48-e7e2-41e7-82f5-d775fa794569',
    localLogo: '/assets/logos/channels/boomerang-logo.svg',
  },
  {
    name: 'NICK JR.',
    id: '97668e49-2622-460f-8637-822e8e1f5fd8',
    localLogo: '/assets/logos/channels/nick-jr-logo.svg',
  },
  {
    id: '97668e49-4d1f-4662-bb6b-b5bf8bc83391',
    localLogo: '/assets/logos/channels/ric-logo.png',
  },
  {
    name: 'FIX & FOXI TV',
    id: '97668e49-94ca-45c1-89ad-a315d26ce643',
    localLogo: '/assets/logos/channels/fix-foxi-2-logo.png',
  },
  {
    id: '97668e49-0f0b-410f-9bab-ff3330590f57',
    localLogo: '/assets/logos/channels/junior-logo.svg',
  },
  {
    id: '97668e49-7ffd-4ea6-a12e-f3ede9d3c806',
    localLogo: '/assets/logos/channels/sky-cinema-comedy-logo.svg',
  },
  {
    name: 'SKY CINEMA PREMIEREN +24',
    id: '97668e49-66c4-4439-a3b3-e77ab8e12c5c',
    localLogo: '/assets/logos/channels/sky-cinema-premieren-24-logo.png',
  },
  {
    id: '97668e49-67e3-44f2-aa3d-5cc7d60e0a88',
    localLogo: '/assets/logos/channels/sky-cinema-best-of-logo.svg',
  },
  {
    id: '97668e49-7c56-4807-8cd6-10ca2cb2fc3f',
    localLogo: '/assets/logos/channels/sky-cinema-thriller-logo.svg',
  },
  {
    id: '97668e49-6a1d-4851-af79-40d2b03aced2',
    localLogo: '/assets/logos/channels/sky-cinema-fun-logo.svg',
  },
  {
    id: '97668e49-6335-455a-b9dc-1c88269c6003',
    localLogo: '/assets/logos/channels/sky-cinema-action.svg',
  },
  {
    id: '97668e49-64f0-4e3f-aeb0-2e98f8603091',
    localLogo: '/assets/logos/channels/sky-atlantic-logo.svg',
  },
  {
    id: '97668e49-7b5a-425b-a222-b0b8efe056c0',
    localLogo: '/assets/logos/channels/sky-cinema-special-logo.svg',
  },
  {
    id: '97668e49-075e-4c7e-ab0c-ed110d3faf3a',
    localLogo: '/assets/logos/channels/sky-one-logo.svg',
  },
  {
    id: '97668e49-7307-4345-b5bd-fe567c63bd8f',
    localLogo: '/assets/logos/channels/sport1-logo.svg',
  },
  {
    name: 'ORF SPORT +',
    id: '97668e49-3a5b-422e-abb3-16c656ac3c1f',
    localLogo: '/assets/logos/channels/orf-sport-plus-logo.svg',
  },
  {
    id: '97668e49-0422-407e-8edf-8430b51b444d',
    localLogo: '/assets/logos/channels/eurosport1-logo.svg',
  },
  {
    id: '97668e49-03e3-444c-bd6a-2286681c0ab6',
    localLogo: '/assets/logos/channels/eurosport2-logo.svg',
  },
  {
    id: '97668e49-981c-47c0-a619-f00b0a6dfe2f',
    localLogo: '/assets/logos/channels/esports1-logo.webp',
  },
  {
    name: 'SPORT1+',
    id: '97668e49-73ad-42c4-a96c-4cbcd8228cf1',
    localLogo: '/assets/logos/channels/sport1plus-logo.svg',
  },
  {
    id: '97668e48-d562-446a-acaa-f486fc1e82be',
    localLogo: '/assets/logos/channels/auto-motor-sport-channel-logo.svg',
  },
  {
    id: '97668e49-1b22-4786-9b1f-aa73c7a56e52',
    localLogo: '/assets/logos/channels/motorvision_tv-logo.png',
  },
  {
    id: '97668e49-7095-49f7-9a06-df9a871a15d8',
    localLogo: '/assets/logos/channels/sky-sport-austria-logo.svg',
  },
  {
    id: '97668e49-70fd-48a8-935b-4aaa01db2acd',
    localLogo: '/assets/logos/channels/sky-sport-news-logo.png',
  },
  {
    name: 'SKY SPORT BUNDESLIGA (steht als SKY BUNDESLIGA)',
    id: '97668e49-65e2-44d7-b783-6c76d3ee865d',
    localLogo: '/assets/logos/channels/sky-sport-bundesliga-logo.svg',
  },
  {
    id: '98bc1acd-072e-48d9-aa2e-124ab8607639',
    localLogo: '/assets/logos/channels/sky-sport-golf.svg',
  },
  {
    id: '98bc1acd-467a-45a5-9185-f11058e8f8da',
    localLogo: '/assets/logos/channels/sky-sport-mix.svg',
  },
  {
    id: '98bc1acd-17e4-45c1-973a-1278bb1a428c',
    localLogo: '/assets/logos/channels/sky-premier-league.svg',
  },
  {
    id: '',
    localLogo: '/assets/logos/channels/',
  },
  {
    id: '9770843e-6e81-4cb6-83c6-763aff50e8db',
    localLogo: '/assets/logos/channels/sky-sport-austria.svg',
  },
  {
    id: '',
    localLogo: '/assets/logos/channels/',
  },
  {
    id: '97668e49-8e1b-45d3-bbd9-a7b80a387c4b',
    localLogo: '/assets/logos/channels/demu-logo.png',
  },
  {
    id: '98bc1acc-33b1-43cc-b27f-c5b9246a4a95',
    localLogo: '/assets/logos/channels/hgtv-logo.svg',
  },
  {
    id: '97668e49-6ce0-47fe-8b4e-227bfff1bb3a',
    localLogo: '/assets/logos/channels/sky-history.png',
  },
  {
    id: '97668e49-8b3c-4fd7-beab-0a6d12ce7195',
    localLogo: '/assets/logos/channels/beate-uhse-logo.svg',
  },
  {
    id: '97668e49-3e68-4d5e-bce6-95614294c69c',
    localLogo: '/assets/logos/channels/planet-tv-logo.svg',
  },
  {
    id: '97668e49-5801-4641-94ac-ea16a048cac3',
    localLogo: '/assets/logos/channels/hope-tv-logo.gif',
  },
  {
    id: '97668e49-6b29-448e-8ddd-5c6ff65ef535',
    localLogo: '/assets/logos/channels/sky-cinema-family-logo.svg',
  },
  {
    id: '97668e49-68e5-4727-812b-e4ead0831a12',
    localLogo: '/assets/logos/channels/sky-cinema-logo.svg',
  },
  {
    id: '97668e49-5db8-4526-b961-bbb847aeb5b8',
    localLogo: '/assets/logos/channels/scifi.png',
  },
  {
    id: '97668e49-0cc8-4119-9d81-47b3a0fdcc91',
    localLogo: '/assets/logos/channels/jukebox-logo.png',
  },
  {
    id: '97668e49-07d7-401d-a1cb-31421d5c650c',
    localLogo: '/assets/logos/channels/gutelaune-logo.png',
  },
  {
    id: '97668e48-fa3b-4686-89df-904c060c98dc',
    localLogo: '/assets/logos/channels/deluxemusic-logo.png',
  },
  {
    id: '97668e49-6fdc-4175-a2be-04e21dc2ef7f',
    localLogo: '/assets/logos/channels/skysport1.png',
  },
  {
    id: '97679df7-3228-4636-bf6c-2bef7af8cf68',
    localLogo: '/assets/logos/channels/skysport1.png',
  },
  {
    id: '97679df7-3258-451d-9a7d-c4f456fb5b92',
    localLogo: '/assets/logos/channels/skysport2.png',
  },
  {
    id: '98bc1acc-f5f2-4936-937a-a17cb88cf576',
    localLogo: '/assets/logos/channels/sky-sport-f1.svg',
  },
  {
    id: '98bc1acd-2deb-43d4-b10a-bb82d8c0eb66',
    localLogo: '/assets/logos/channels/sky-premier-league.svg',
  },
  {
    id: '97668e48-f9dd-4f25-930a-483b682d7949',
    localLogo: '/assets/logos/channels/folx-logo.png',
  },
  {
    id: '97668e49-0015-45a6-ae40-a672967fb00f',
    localLogo: '/assets/logos/channels/eo-logo.svg',
  },
  {
    name: 'Kärnten TV',
    id: '98bc1acc-5298-430c-9739-4a63d532f3ea',
    localLogo: '/assets/logos/channels/kaernten-tv-logo.png',
  },
  {
    id: '98bc1acd-6aac-46e5-ae6f-e98eeed07549',
    localLogo: '/assets/logos/channels/warnertv-comedy-logo.svg',
  },
  {
    id: '97668e49-81ab-4f2f-a353-5e73f7543283',
    localLogo: '/assets/logos/channels/skycrime.png',
  },
  {
    id: '97668e49-83be-4973-b89c-e498d29a06d5',
    localLogo: '/assets/logos/channels/sky-nature-logo.webp',
  },
  {
    id: '97668e49-82c3-4fd9-8d65-7d30e09caa4b',
    localLogo: '/assets/logos/channels/skydocumentaries.png',
  },
  {
    id: '97668e49-6f90-485f-b01d-1b5e394589dd',
    localLogo: '/assets/logos/channels/skynostalgie.svg',
  },
];

export const getChannelLogo = (channelId: string): string => {
  const channel = sortedChannels.find((channel) => channel.id === channelId);
  return channel?.localLogo || '';
};
